<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>{{ $t('actionReportingPromotionStatsLabel') }}:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">
          {{ $t('actionReportingStatsArrivedLabel') }}:
        </div>
        <div class="stats-bar-item-number">{{ arrivedTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="tick-box" />
        <div class="stats-bar-item-title">
          {{ $t('actionReportingStatsSentLabel') }}:
        </div>
        <div class="stats-bar-item-number">{{ sentTotal | number }}</div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="cross-circle" />
        <div class="stats-bar-item-title">
          {{ $t('actionReportingStatsFailedLabel') }}:
        </div>
        <div class="stats-bar-item-number">{{ failedTotal | number }}</div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      :title="$t('actionReportingHowManyProfilesArrived')"
      title-class="action-color-start"
      :y-label="$t('actionReportingNumberOfProfiles')"
    />
  </div>
</template>

<script>
import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'
import LineChart from '@/components/DateLineChart'

export default {
  components: {
    LineChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  computed: {
    arrivedTotal() {
      return this.getTotal('Promotion:trigger')
    },

    arrivedStats() {
      return this.getStats('Promotion:trigger')
    },

    sentTotal() {
      return this.getTotal('Promotion:sent')
    },

    sentStats() {
      return this.getStats('Promotion:sent')
    },

    failedTotal() {
      return this.getTotal('Promotion:failed')
    },

    failedStats() {
      return this.getStats('Promotion:failed')
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingLineChartArrived'),
            backgroundColor: 'RGBA(85, 83, 83, .2)',
            borderColor: 'RGBA(85, 83, 83, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            label: this.$t('actionReportingLineChartSent'),
            backgroundColor: 'RGBA(0, 171, 102, .2)',
            borderColor: 'RGBA(0, 171, 102, 1)',
            borderWidth: 1,
            data: this.sentStats
          },
          {
            label: this.$t('actionReportingLineChartFailed'),
            backgroundColor: 'RGBA(221, 56, 88, .2)',
            borderColor: 'RGBA(221, 56, 88, 1)',
            borderWidth: 1,
            data: this.failedStats
          }
        ]
      }
    }
  }
}
</script>

<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipArrived')" glyph="user" />
      {{ stats['Promotion:trigger'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="'Sent'" glyph="tick-box" />
      {{ stats['Promotion:sent'] | number }}
    </li>
    <li>
      <icon v-tooltip:left="'Failed'" glyph="cross-circle" />
      {{ stats['Promotion:failed'] | number }}
    </li>
  </ul>
</template>
<script>
export default {
  props: ['stats']
}
</script>
